import React from "react"
import { graphql } from 'gatsby'
import Layout from "./layout"

export const query = graphql`
query GroepQuery($groep_regex: String) {
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: $groep_regex}}) {
      edges {
        node {
          fileAbsolutePath
          frontmatter {
            leiding
            tekst
            name
            image
          }
        }
      }
    }
  }
`

const Groep = ({data}) => {
    const name = data.allMarkdownRemark.edges[0].node.frontmatter.name
    const tekst = data.allMarkdownRemark.edges[0].node.frontmatter.tekst
    const image = data.allMarkdownRemark.edges[0].node.frontmatter.image

    const Tekst = () => {
      return { __html: `${ tekst }` }
    }

    return (
    <Layout pageTitle={name}>
      <div class="py-8">
        <img class="object-scale-down rounded-md max-h-96 mx-auto" src={image} alt="A kitten" />
        <div class="py-8" dangerouslySetInnerHTML={Tekst()}></div>
      </div>
    </Layout>  
    )
  }

export default Groep